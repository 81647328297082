import React, { Component } from "react"
import { MDBContainer, MDBRow, MDBCol, MDBCollapse } from "mdbreact"

class FAQPanel extends Component {
    state = {
        currentOpenPanel: 0,
    }

    togglePanel = (e, panelID) => {
        e.preventDefault();
        if(this.state.currentOpenPanel === panelID)
            this.setState({currentOpenPanel: 0})
        else
            this.setState({currentOpenPanel: panelID})
    }

    render() {
        var questionStyle = {
            fontSize: '20px',
        }

        var questionRowStyle = {
            backgroundColor: 'rgb(220,220,220)',
            marginTop: '5px',
        }

        var answerRowStyle = {
            border: '1px solid rgb(220,220,200)',
        }

        return(
            <MDBContainer fluid>
                <MDBRow style={questionRowStyle}>
                    <MDBCol md="11" className="text-left align-self-center"><a href="#" onClick={(e) => this.togglePanel(e, 1)} style={questionStyle}>What is your favorite color?</a></MDBCol>
                    <MDBCol md="1" className="text-center align-self-center"><i class={(this.state.currentOpenPanel === 1) ? "fas fa-angle-up" : "fas fa-angle-down"} style={questionStyle}></i></MDBCol>
                </MDBRow>
                <MDBRow style={answerRowStyle}>
                    <MDBCollapse id="detailingCollapse" isOpen={(this.state.currentOpenPanel == 1) ? true : false}>
                        <MDBCol>
                            <MDBContainer fluid>
                                <p>
                                    Here is the answer to your question. My favorite color is orange. I don't know why this is, but it is the case. I also can't think of anything else to take up space in this answer and yet I know that I have to to test the formatting.
                                </p>
                            </MDBContainer>
                        </MDBCol>
                    </MDBCollapse>                                        
                </MDBRow>

                <MDBRow style={questionRowStyle}>
                    <MDBCol md="11" className="text-left align-self-center"><a href="#" onClick={(e) => this.togglePanel(e, 2)} style={questionStyle}>What is your favorite color?</a></MDBCol>
                    <MDBCol md="1" className="text-center align-self-center"><i class={(this.state.currentOpenPanel === 2) ? "fas fa-angle-up" : "fas fa-angle-down"} style={questionStyle}></i></MDBCol>
                </MDBRow>
                <MDBRow style={answerRowStyle}>
                    <MDBCollapse id="detailingCollapse" isOpen={(this.state.currentOpenPanel == 2) ? true : false}>
                        <MDBCol>
                            <MDBContainer fluid>
                                <p>
                                    Here is the answer to your question. My favorite color is orange. I don't know why this is, but it is the case. I also can't think of anything else to take up space in this answer and yet I know that I have to to test the formatting.
                                </p>
                            </MDBContainer>
                        </MDBCol>
                    </MDBCollapse>                                        
                </MDBRow>

                <MDBRow style={questionRowStyle}>
                    <MDBCol md="11" className="text-left align-self-center"><a href="#" onClick={(e) => this.togglePanel(e, 3)} style={questionStyle}>What is your favorite color?</a></MDBCol>
                    <MDBCol md="1" className="text-center align-self-center"><i class={(this.state.currentOpenPanel === 3) ? "fas fa-angle-up" : "fas fa-angle-down"} style={questionStyle}></i></MDBCol>
                </MDBRow>
                <MDBRow style={answerRowStyle}>
                    <MDBCollapse id="detailingCollapse" isOpen={(this.state.currentOpenPanel == 3) ? true : false}>
                        <MDBCol>
                            <MDBContainer fluid>
                                <p>
                                    Here is the answer to your question. My favorite color is orange. I don't know why this is, but it is the case. I also can't think of anything else to take up space in this answer and yet I know that I have to to test the formatting.
                                </p>
                            </MDBContainer>
                        </MDBCol>
                    </MDBCollapse>                                        
                </MDBRow>                                             
            </MDBContainer>
        )
    }
}

export default FAQPanel