import React from "react"
import { MDBContainer, MDBRow, MDBCol } from "mdbreact"
import QuickContactForm from "../components/QuickContactForm"
import FAQPanel from "../components/FAQPanel"

import Layout from "../components/TLCLayout"
import RoatatingTestimonials from "../components/RotatingTestimonials"

const AboutPage = () => (
    <Layout heroTitle="About Us" heroImageUrl="./images/hero-dark.jpg">
        <a name="ourteam" />  
        <MDBContainer style={{paddingTop: '50px', paddingBottom: '50px'}}>
            <MDBRow>
                <MDBCol md="7">        
                    <h3>Our Team</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Pellentesque dignissim enim sit amet venenatis urna. Feugiat nibh sed pulvinar proin gravida hendrerit lectus a. Lacus suspendisse faucibus interdum posuere lorem ipsum dolor sit amet. Posuere sollicitudin aliquam ultrices sagittis orci a scelerisque purus semper. Diam quis enim lobortis scelerisque fermentum dui faucibus in. Nunc id cursus metus aliquam eleifend mi in nulla. Aliquam nulla facilisi cras fermentum odio eu feugiat pretium. Integer malesuada nunc vel risus commodo viverra maecenas. Elementum eu facilisis sed odio morbi.</p>
                    <p>Ornare massa eget egestas purus viverra accumsan in. Orci eu lobortis elementum nibh tellus molestie nunc non blandit. Blandit cursus risus at ultrices mi tempus imperdiet nulla. Ultrices gravida dictum fusce ut. Quis varius quam quisque id diam vel quam elementum. Faucibus et molestie ac feugiat sed lectus vestibulum.</p>

                    <a name="faq" />
                    <h3 style={{marginTop: '50px', marginBottom: '25px'}}>Frequently Asked Questions</h3>
                    <FAQPanel />

                    <a name="reviews" />
                    <h3 style={{marginTop: '50px', marginBottom: '25px'}}>Reviews</h3>
                    <RoatatingTestimonials />
                </MDBCol>
                <MDBCol md="5">
                    <QuickContactForm />
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    </Layout>
)

export default AboutPage