import React, { Component } from "react"
import { MDBTypography, MDBBox } from "mdbreact"

class RoatatingTestimonials extends Component {
    render() {
        return (
            <MDBTypography blockquote>
                <MDBBox tag="p" mb={0}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</MDBBox>
                <MDBBox tag="footer" mb={3} className="blockquote-footer">Name of the reviewer</MDBBox>
            </MDBTypography>
        )
    }
}

export default RoatatingTestimonials