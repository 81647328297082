import React, { Component } from "react"
import { MDBContainer, MDBCard, MDBRow, MDBCol, MDBCardBody, MDBInput, MDBBtn, MDBCollapse } from "mdbreact"

class QuickContactForm extends Component {
    state = {
        detailingOpen: false,
        coatingsOpen: false,
        filmsOpen: false,
    }    
    
    toggleDetailing = (e) => {
        e.preventDefault();
        this.setState({ detailingOpen: !this.state.detailingOpen })
    }

    toggleCoatings = (e) => {
        e.preventDefault();
        this.setState({ coatingsOpen: !this.state.coatingsOpen })
    }

    toggleFilms = (e) => {
        e.preventDefault();
        this.setState({ filmsOpen: !this.state.filmsOpen })
    }

    render(){
        var checkBoxGroupStyle = {
            // border: 'solid 1px #ccc',
            padding: '.25em',
            margin: '.1em',
        }

        return (
            <MDBContainer fluid>
                    <MDBCard>
                        <MDBCardBody>
                        <form name="Quick Quote" data-netlify="true" method="POST">
                            <input type="hidden" name="form-name" value="Quick Quote" />
                            <p className="h4 text-center py-4">Get a Quote Today!</p>
                            <div className="grey-text">
                            <p>I'm interested in:</p>
                            <MDBContainer fluid style={checkBoxGroupStyle}>
                                <i class={(this.state.detailingOpen) ? "fas fa-angle-down" : "fas fa-angle-right"} style={{paddingRight: '.5em'}}></i>
                                <a href="#" onClick={this.toggleDetailing}>Detailing Services</a><span style={{float: 'right'}}></span>
                                <MDBCollapse id="detailingCollapse" isOpen={this.state.detailingOpen}>
                                    <p><input type="checkbox" name="Interested in Maintenance Wash?" value="Yes" />&nbsp;Maintenance Wash</p>
                                    <p><input type="checkbox" name="Interested in Signature Protection Detail?" value="Yes" />&nbsp;Signature Protection Detail</p>
                                    <p><input type="checkbox" name="Interested in Interior Detail?" value="Yes" />&nbsp;Interior Detail</p>
                                    <p><input type="checkbox" name="Interested in Paint Correction?" value="Yes" />&nbsp;Paint Correction</p>
                                </MDBCollapse>
                            </MDBContainer>
                            <MDBContainer fluid style={checkBoxGroupStyle}>
                                <i class={(this.state.coatingsOpen) ? "fas fa-angle-down" : "fas fa-angle-right"} style={{paddingRight: '.5em'}}></i>                                
                                <a href="#" onClick={this.toggleCoatings}>Modesta Coatings</a>
                                <MDBCollapse id="coatingsCollapse" isOpen={this.state.coatingsOpen}>                          
                                    <p><input type="checkbox" name="Interested in Modesta Body Coating?" value="Yes" />&nbsp;Modesta Body Coating</p>
                                    <p><input type="checkbox" name="Interested in Modesta Wheel Coating?" value="Yes" />&nbsp;Modesta Wheel Coating</p>
                                    <p><input type="checkbox" name="Interested in Modesta Leather Protection System?" value="Yes" />&nbsp;Modesta Leather Protection System</p>
                                    <p><input type="checkbox" name="Interested in Modesta Exerior Plastic Coating?" value="Yes" />&nbsp;Modesta Exterior Plastic Coating</p>                            
                                </MDBCollapse>
                            </MDBContainer>
                            <MDBContainer style={checkBoxGroupStyle}>
                                <i class={(this.state.filmsOpen) ? "fas fa-angle-down" : "fas fa-angle-right"} style={{paddingRight: '.5em'}}></i>                                
                                <a href="#" onClick={this.toggleFilms}>Protection Films</a>
                                <MDBCollapse id="coatingsCollapse" isOpen={this.state.filmsOpen}>                            
                                    <p><input type="checkbox" name="Interested in Custom Paint Protection Film?" value="Yes" />&nbsp;Custom Paint Protection Film</p>
                                    <p><input type="checkbox" name="Interested in ExoShield Windshield Protection Film?" value="Yes" />&nbsp;ExoShield Windshield Protection Film</p>
                                    <p><input type="checkbox" name="Interested in Spectra PhotoSyng Window Tint" value="Yes" />&nbsp;Spectra PhotoSync Window Tint</p>
                                </MDBCollapse>
                            </MDBContainer>
                            <MDBInput
                                name="Vehicle Make and Model"
                                label="Vehicle Make and Model"
                                group
                                type="text"
                                validate
                                error="wrong"
                                success="right"
                            />
                            <MDBInput
                                name="Name"
                                label="Your Name"
                                group
                                type="text"
                                validate
                                error="wrong"
                                success="right"
                            />
                            <MDBInput
                                name="Email"
                                label="Email"
                                group
                                type="email"
                                validate
                                error="wrong"
                                success="right"
                            />
                            <MDBInput
                                name="Phone Number"
                                label="Phone Number"
                                group
                                type="text"
                                validate
                            />
                            <MDBInput
                                name="Notes"
                                label="Notes"                             
                                name="Message"
                                type="textarea"
                                rows="2" 
                            />
                            </div>
                            <div className="text-center py-4 mt-3">
                            <MDBBtn color="cyan" type="submit">
                                Get a Quote
                            </MDBBtn>
                            </div>
                        </form>
                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>            
        )
    }
}

export default QuickContactForm